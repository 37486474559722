import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { StyleType, LinkType } from '../utils/prop-types'
import { lg, xl, xxl } from '../utils/breakpoints'
import VideoPlayer from './video-player'
import Image from './image'
import Text from './text'
import VideoButton from './video-button'
import loadable from '@loadable/component'
//import Animation from './animation'
const Animation = loadable(() => import('./animation'))

const VerticalCarousel = ({ slides, style }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    axis: 'y',
    align: 'start',
  })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla])

  const onSelect = useCallback(() => {
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla])

  const btnTitle = useMemo(() => slides.map((slide) => slide.videoBtnTitle))

  useEffect(() => {
    if (!embla) return
    embla.on('select', onSelect)
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
  }, [embla, onSelect])

  return (
    <>
      <div
        css={[global`layout.container`, global`layout.grid`, tw`relative hidden lg:grid`, style]}
      >
        <div css={tw`lg:(mb-12 relative col-span-7) xl:(col-start-1 col-end-9) xxl:mb-10`}>
          {scrollSnaps.map((_, index) => (
            <>
              <button
                key={index}
                onClick={() => scrollTo(index)}
                css={[
                  [
                    tw`transition-opacity duration-300 ease-in-out opacity-60 w-44`,
                    global`base.hover-underline`(),
                  ],
                  index === selectedIndex && [tw`opacity-100 `, global`base.hover-underline`()],
                ]}
              >
                <Text
                  content={btnTitle[index]}
                  textType="h5"
                  style={css`
                    ${index === selectedIndex
                      ? tw`font-bold transform motion-safe:hover:scale-110`
                      : tw`transform motion-safe:hover:scale-110`}
                  `}
                />
                <div css={tw`lg:(w-40 mt-6 h-0.5 bg-primary-500) xl:w-52 xxl:w-60`} />
              </button>
            </>
          ))}
        </div>
        <div
          css={tw`col-span-4 md:col-span-8 lg:(mt-4 col-span-6 relative) xl:(col-start-1 col-end-8)`}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              css={[
                tw`absolute inset-0 flex flex-col justify-center transition-opacity duration-300 ease-in-out opacity-0 lg:pr-6 xl:pl-0`,
                selectedIndex === index && tw`opacity-100`,
              ]}
            >
              <Text content={slide.titleNode} textType="section" />
              <Text content={slide.descriptionNode} style={tw`mt-10`} />
              <div css={[tw`py-6 z-10 lg:(pr-48) xxl:(pr-76)`]}>
                {slide?.videoBtn && (
                  <VideoButton
                    streamingUrl={slide.videoBtn.video.mp4Url}
                    thumbnailUrl={slide?.imageBtn?.url}
                    title={slide.videoBtnTitle}
                    subtitle={slide.videoBtnSubtitle}
                    style={tw`mt-6 lg:mt-8`}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div
          ref={viewportRef}
          css={tw`overflow-hidden relative lg:(h-160 col-span-6 -mr-container-lg) xl:(col-start-9 col-end-17 -mr-container-xl) xxl:(h-hero-video-mobile -mr-container-xxl)`}
        >
          <div css={tw`space-y-20`}>
            {slides.map((slide, index) => (
              <div
                key={index}
                css={[
                  tw`relative z-1 h-160 grid gap-10`,
                  slide.video !== null ? tw`grid-cols-1` : tw`grid-cols-3`,
                ]}
              >
                {slide?.leftImage && (
                  <div css={tw`w-full h-full`}>
                    <Image image={slide.leftImage} />
                  </div>
                )}

                {slide?.middleAnimation && (
                  <Animation
                    url={slide?.middleAnimation?.url}
                    play
                    options={{
                      loop: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMin slice',
                      },
                    }}
                    style={css`
                      ${lg} {
                        max-height: calc(308px + (84) * ((100vw - 1024px) / 255));
                        margin-top: 13rem;
                      }
                      ${xl} {
                        max-height: calc(392px + (108) * ((100vw - 1280px) / 320));
                        margin-top: calc(160px + (-32) * ((100vw - 1280px) / 320));
                      }
                      ${xxl} {
                        max-height: calc(500px + (112) * ((100vw - 1601px) / 319));
                        margin-top: 7rem;
                      }
                    `}
                  />
                )}

                {slide.rightImage && (
                  <div css={tw`w-full h-full`}>
                    <Image image={slide.rightImage} />
                  </div>
                )}

                {slide.video && (
                  <VideoPlayer
                    streamingUrl={slide?.video?.video?.mp4Url}
                    thumbnailUrl={slide?.video?.video?.mp4Url}
                    playIcon={false}
                    thumbnailClick={false}
                    defaultHover
                    style={css`
                      padding-top: 62.5%;
                    `}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

VerticalCarousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object),
  callToAction: LinkType,
  buttonTheme: PropTypes.string,
  style: StyleType,
}

export default VerticalCarousel
