import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, StyleType } from '../utils/prop-types'
import Text from './text'
import VideoPlayer from './video-player'
import Heading from './heading'

const VideoButton = ({ streamingUrl, thumbnailUrl, title, subtitle, style }) => {
  return (
    <>
      <div css={[global`layout.container`, tw`relative lg:(-mt-16 mb-32)`, style]}>
        <div
          css={[
            tw`bg-accent-50 w-56 h-14 lg:( w-72 h-18 -mb-24)`,
            css`
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
            `,
          ]}
        >
          <VideoPlayer
            streamingUrl={streamingUrl}
            thumbnailUrl={thumbnailUrl}
            thumbStyle={tw`h-14 w-14 lg:(h-18 w-24)`}
            buttonLeft
            videoButton
          />
        </div>
        <div css={[tw`absolute h-14 w-36 text-primary-500 ml-20 -mt-14 lg:(h-18 ml-32 mt-9)`]}>
          <div>
            <Text content={subtitle} style={tw`text-xs`} />
            <Heading content={title} headingType="h6" style={tw`font-bold`} />
          </div>
        </div>
      </div>
    </>
  )
}

VideoButton.propTypes = {
  streamingUrl: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  title: HTMLType,
  subtitle: HTMLType,
  thumbStyle: StyleType,
  style: StyleType,
}

export default VideoButton
